// DATE PICKER JS
$('input.book').datepicker({
  beforeShow: function(input, inst) {
    $('#ui-datepicker-div').removeClass(function() {
      return $('input.book').get(0).id;
    });
    $('#ui-datepicker-div').addClass('clifton-booking');
  },
});
(function() {
  $('#js-booking input').datepicker({
    dateFormat: 'dd/mm/yy',
    nextText: 'Later',
    prevText: 'Earlier',
  });

  $('#js-booking').on('submit', function(evt) {
    evt.preventDefault();

    var arrival = $('#arrival').val();
    var depart = $('#depart').val();

    function processDate(date) {
      var parts = date.split('/');
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    function compareDates(date1, date2) {
      return processDate(date1) > processDate(date2);
    }

    function formatDate(originalDate) {
      var dateArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var formattedDate = new processDate(originalDate);
      var d = formattedDate.getDate();
      var m = formattedDate.getMonth();
      m += 1;
      var y = formattedDate.getFullYear();
      return y + '-' + m + '-' + d;
    }

    if (arrival !== '' && depart !== '') {
      if (compareDates(depart, arrival)) {
        window.location =
          'https://secure.staah.com/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=646&checkIn=' +
          formatDate(arrival) +
          '&checkOut=' +
          formatDate(depart) +
          '&unk=1347';
      } else {
        alert('Your departure date must be after your arrival date.');
      }
    } else {
      alert('Please enter your arrival and departure dates.');
    }
  });
})();
(function() {
  $('body').on('change', '#arrival', function() {
    var thisDate = $(this).val();
    $('#depart').datepicker('setDate', thisDate);
  });
})();
