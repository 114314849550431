// Form GDPR Validation
$('#send-btn').mouseenter(function() {
  if ($('input[type="checkbox"].ppolicy').is(':checked')) {
    $('#send-btn').attr('disabled', false);
  } else {
    $('#send-btn').attr('disabled', true);
    alert(
      'Please ensure that you have read and accepted our Privacy Policy. If you accept the terms, please check the box to confirm before sending this form.'
    );
  }
});

// Navigation
$('.toggle-menu').click(function() {
  $('nav').toggleClass('open close');
});

// Colour change navigation
$('nav ul li a').hover(
  function(e) {
    var color = $(this).data('color');

    $('.col-ch').css('background-color', color);
  },
  function(e) {
    $('.col-ch').css('background-color', '');
  }
);

// Hero slider
$(document).ready(function() {
  $('#hero-slider').bxSlider({
    auto: true,
    mode: 'fade',
    pager: false,
    controls: false,
    touchEnabled: false
  });
});

// Page slider
$(document).ready(function() {
  var pslider = $('#p-slider').bxSlider({
    auto: false,
    mode: 'horizontal',
    pager: true,
    controls: false,
    touchEnabled: false,
    pagerCustom: '#bx-pager-p',
    onSlideAfter: function() {
      var pcurrent = pslider.getCurrentSlide();
      $('.page-start').html(pcurrent + 1);
    }
  });
});

// Wide slider
$(document).ready(function() {
  var wslider = $('#w-slider').bxSlider({
    auto: false,
    mode: 'horizontal',
    pager: true,
    controls: false,
    touchEnabled: false,
    pagerCustom: '#bx-pager-w',
    onSlideAfter: function() {
      var wcurrent = wslider.getCurrentSlide();
      $('.wide-start').html(wcurrent + 1);
    }
  });
});

// Opening Times slider
$(document).ready(function() {
  var otslider = $('#otimes-slider').bxSlider({
    auto: false,
    mode: 'horizontal',
    pager: true,
    controls: false,
    touchEnabled: false,
    pagerCustom: '#bx-pager-otimes',
    onSlideAfter: function() {
      var otcurrent = otslider.getCurrentSlide();
      $('.otimes-start').html(otcurrent + 1);
    }
  });
});

// Opening Times slider
$(document).ready(function() {
  var planSlider = $('#rooms-slider').bxSlider({
    auto: false,
    mode: 'horizontal',
    pager: false,
    controls: true,
    infinite: false,
    adaptiveHeight: true,
    touchEnabled: false,
    pagerCustom: '#bx-pager-otimes'
  });
});

// Content Block slider
$(document).ready(function() {
  // Set a counter
  cbSliderCount = 1;
  // For each result
  $('.cb-slider-cont').each(function() {
    var cSlider = '#cb-number-' + cbSliderCount + ' .cb-current';
    var sliderId = $('#cb-slider-' + cbSliderCount).bxSlider({
      auto: false,
      mode: 'horizontal',
      pager: true,
      controls: false,
      touchEnabled: false,
      pagerCustom: '#cb-pager-' + cbSliderCount,
      onSlideAfter: function() {
        var currentSlide = sliderId.getCurrentSlide();
        $(cSlider).html(currentSlide + 1);
      }
    });
    cbSliderCount++;
  });
});

$(document).ready(function() {
  // Set a counter
  tiSliderCount = 1;
  // For each result
  $('.ti-slider-cont').each(function() {
    var tiSlider = '#ti-number-' + tiSliderCount + ' .ti-current';
    var tisliderId = $('#ti-slider-' + tiSliderCount).bxSlider({
      auto: false,
      mode: 'horizontal',
      pager: true,
      controls: false,
      touchEnabled: false,
      pagerCustom: '#ti-pager-' + tiSliderCount,
      onSlideAfter: function() {
        var ticurrentSlide = tisliderId.getCurrentSlide();
        $(tiSlider).html(ticurrentSlide + 1);
      }
    });
    tiSliderCount++;
  });
});

$(document).ready(function() {
  // Set a counter
  hsSliderCount = 0;
  // For each result
  $('.hs-slider-cont').each(function() {
    var hsSlider = '#hs-number-' + hsSliderCount + ' .hs-current';
    var hssliderId = $('#hs-slider-' + hsSliderCount).bxSlider({
      auto: false,
      mode: 'horizontal',
      pager: true,
      controls: false,
      touchEnabled: false,
      pagerCustom: '#hs-pager-' + hsSliderCount,
      onSlideAfter: function() {
        var hscurrentSlide = hssliderId.getCurrentSlide();
        $(hsSlider).html(hscurrentSlide + 1);
      }
    });
    hsSliderCount++;
  });
});

// SVG replace
jQuery('img.svg').each(function() {
  var $img = jQuery(this);
  var imgID = $img.attr('id');
  var imgClass = $img.attr('class');
  var imgURL = $img.attr('src');

  jQuery.get(
    imgURL,
    function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Replace image with new SVG
      $img.replaceWith($svg);
    },
    'xml'
  );
});
